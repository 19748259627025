<template>
  <div>
    <!--面包屑-->
    <div class="breadcrumb">
      <el-button type="primary" :loading="loading" @click="enable"
        >确定</el-button
      >
      <el-button @click="$router.go(-1)">取消</el-button>
    </div>
    <!--主体内容-->
    <div class="content">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="130px"
        label-position="right"
      >
        <el-form-item label="商家编号：" prop="storeCode">
          <el-input
            style="width: 300px"
            maxlength="20"
            show-word-limit
            v-model="ruleForm.storeCode"
            placeholder="请输入商家编号"
          />
        </el-form-item>
        <el-form-item label="商家名称：" prop="storeName">
          <el-input
            style="width: 300px"
            maxlength="30"
            show-word-limit
            v-model="ruleForm.storeName"
            placeholder="请输入商家名称"
          />
        </el-form-item>
        <el-form-item label="商家描述：">
          <el-input
            style="width: 300px"
            maxlength="50"
            show-word-limit
            v-model="ruleForm.description"
            placeholder="请输入商家描述"
          />
        </el-form-item>
        <el-form-item label="启用：">
          <el-switch
            v-model="ruleForm.storeState"
            active-color="#409EFF"
            inactive-color="#909399"
            active-value="1"
            inactive-value="2"
          >
          </el-switch>
        </el-form-item>
        <el-form-item label="账户余额：" v-if="isEdit">
          <span>{{ balance || "0.00" }}元</span>
          <el-button type="text" style="margin: 0 40px" @click="seeDetail"
            >查看明细</el-button
          >
          <el-button type="text" style="color: #f56c6c" @click="refund"
            >退款</el-button
          >
        </el-form-item>
        <div class="title">合作车场</div>
        <el-form-item label="商户名称：" prop="operationId">
          <el-select v-model="ruleForm.operationId" @change="getStoreParks">
            <el-option
              v-for="value in tenantList"
              :label="value.operationName"
              :value="value.operationId"
              :key="value.operationId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="合作车场：" prop="parkName">
          <el-transfer
            :titles="['未选', '已选']"
            filterable
            :props="{
              key: 'parkId',
              label: 'parkName',
            }"
            filter-placeholder="请输入车场名称"
            v-model="selected"
            :data="parkList"
          >
          </el-transfer>
        </el-form-item>
      </el-form>
      <div></div>
    </div>
    <refund
      ref="refund"
      :balance="balance"
      :storeId="ruleForm.storeId"
      :storeName="ruleForm.storeName"
    ></refund>
  </div>
</template>

<script>
import refund from "./refund";
export default {
  data() {
    let checkCode = (rule, value, callback) => {
      if (!/^[a-z0-9]+$/i.test(value)) {
        callback(new Error("编码格式不正确!"));
      } else {
        callback();
      }
    };
    return {
      options: [],
      dialogVisible: false,
      slotTitle: "",
      slotType: 0,
      loading: false,
      uploadHeader: {
        "Session-Id": sessionStorage.token,
      },
      fileList: [],
      hideUploadEdit: false,
      image: [],
      formInline: {},
      ruleForm: {
        storeCode: "",
        storeName: "",
        description: "",
        operationId: "",
      },
      settleForm: {},
      checkForm: {},
      rules: {
        storeCode: [
          { required: true, message: "请输入商家编号", trigger: "blur" },
          { validator: checkCode, trigger: "blur" },
        ],
        storeName: [
          { required: true, message: "请输入商家名称", trigger: "blur" },
        ],
        description: [
          { required: true, message: "请输入商家描述", trigger: "blur" },
        ],
        operationId: [
          { required: true, message: "请输入商户名称", trigger: "change blur" },
        ],
        // parkName: [
        //   { message: "请输入车场信息", trigger: "change" },
        //   { validator: checkSelect, trigger: "change" },
        // ],
      },
      isEdit: this.$route.query.storeId !== undefined,
      storeId: this.$route.query.storeId,
      operationCode: "",
      operationId: "",
      tenantList: [],
      parkList: [],
      selected: [],
      balance: "0.00",
      storeParks: [],
    };
  },
  components: {
    refund,
  },
  mounted() {
    console.log(this.$route.query, "this.$route.query;");
    if (this.isEdit) {
      const { storeId, storeCode, storeName, description, storeState } =
        this.$route.query;
      this.ruleForm = {
        storeId: storeId,
        storeCode: storeCode,
        storeName: storeName,
        description: description,
        storeState: storeState,
      };
      console.log(this.ruleForm, "------------");
      this.ruleForm.operationId = this.$route.query.operationId;
      if (this.$route.query.parkIds) {
        this.selected = this.$route.query.parkIds.split(",");
      }

      if (this.$route.query.operationId) {
        this.getStoreParks();
        this.getBalance();
      }
    }

    this.getTenantList();
  },
  methods: {
    // 将区域数据格式重排
    getFormattedList(value) {
      value.forEach((item, index) => {
        const keyMap = {
          areaId: "value",
          areaName: "label",
          childrenAreas: "children",
        };
        for (const key in item) {
          const newKey = keyMap[key];
          if (newKey) {
            item[newKey] = item[key];
            delete item[key];
          }
        }
        if (item.children) {
          if (item.children.length === 0) {
            delete item.children;
            return;
          }
          this.getFormattedList(item.children);
        }
      });
      return value;
    },
    // 启用
    enable() {
      this.formInline = Object.assign({}, this.ruleForm);
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          if (this.selected.length == 0) {
            this.$alert("请输入车场信息", this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
            return 0;
          }
          const { storeCode, storeName, description } = this.formInline;
          let tempData = {};
          tempData.storeCode = storeCode;
          tempData.storeName = storeName;
          tempData.description = description;
          this.saveData(tempData);
        }
      });
    },
    // 保存
    saveData(tempData) {
      tempData.storeState = this.ruleForm.storeState;
      if (this.isEdit) {
        tempData.storeId = this.storeId;
        tempData.operationCode = this.operationCode;
      }
      tempData.operationId = this.ruleForm.operationId;
      console.log(this.selected, "this.selected");

      // tempData.parkIds = this.selected;
      this.loading = true;
      // console.log(tempData, "tempData");
      // console.log(this.selected, "this.selected");
      const opt = {
        url: this.isEdit ? "/acb/2.0/store/update" : "/acb/2.0/store/add",
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        data: { ...tempData, parkIds: JSON.stringify(this.selected) },
        success: (res) => {
          this.loading = false;
          if (res.state == 0) {
            this.$router.push({ path: "/storeManage" });
            this.$message({
              message: res.desc,
              type: "success",
            });
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
        fail: (res) => {
          this.loading = false;
          //   // this.$message.error('提交表单失败!')
          //   this.$alert(res.desc, this.$t('pop_up.Tips'), {
          //     confirmButtonText: this.$t('pop_up.Determine')
          //   });
        },
      };
      this.$request(opt);
    },
    // 支付收款帐户字段变动
    handleChange(value) {
      this.settleForm = {};
      this.checkForm = {};
    },
    // 打开清结算弹窗
    checkConfig(type) {
      this.slotType = type;
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs["settleForm"].clearValidate();
      });
      switch (type) {
        case 1:
          this.slotTitle = "APP (微信支付)";
          break;
        case 2:
          this.slotTitle = "APP (支付宝支付)";
          break;
        case 3:
          this.slotTitle = "微信公众号及小程序";
          break;
        case 4:
          this.slotTitle = "支付宝生活号及小程序";
          break;
        case 5:
          this.slotTitle = "APP (微信支付)";
          break;
        case 6:
          this.slotTitle = "APP (支付宝支付)";
          break;
        case 7:
          this.slotTitle = "微信公众号及小程序";
          break;
        case 8:
          this.slotTitle = "支付宝生活号及小程序";
          break;
        default:
          break;
      }
    },
    // 临时保存清结算数据
    saveSettleData() {
      this.$refs["settleForm"].validate((valid) => {
        if (valid) {
          this.dialogVisible = false;
          this.checkForm = Object.assign({}, this.settleForm);
        }
      });
    },
    // 处理上传文件
    handleFiles(file) {
      // const fileItem = URL.createObjectURL(file.file)
      this.image.push(file.file);
    },
    // 上传前验证
    beforeUpload(file) {
      const suffix = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isJpg = suffix === "jpg";
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isJpg) {
        this.$message.warning("图片格式错误，只支持jpg格式!");
        return false;
      }
      if (!isLt5M) {
        this.$message.warning("上传图片大小不能超过 5M!");
        return false;
      }
      return true;
    },
    // 处理文件变化回调
    handleOnChange(file, fileList) {
      this.hideUploadEdit = fileList.length >= 1;
    },
    // 删除上传文件
    handleRemove(file, fileList) {
      console.log(fileList, "fileList");
      this.fileList = fileList;
      this.hideUploadEdit = fileList.length >= 1;
      this.image = [];
    },
    /* 商户名称的下拉框 */
    getTenantList() {
      this.$axios.get("/acb/2.0/operation/nameList").then((res) => {
        if (res.state == 0) {
          this.tenantList = res.value;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    // 获取商户所辖车场
    getStoreParks(val) {
      let url =
        "/acb/2.0/bacb/systems/loginUser/getParkName?page=0&pageSize=0&slaveRelations=0,1&operationId=" +
        this.ruleForm.operationId;
      this.$axios.get(url).then((res) => {
        if (res.state != 0) {
          this.$alert(res.desc);
          return;
        }
        this.parkList = res.value;
      });
    },
    getBalance() {
      const opt = {
        url: `/acb/2.0/storeBalanceRecord/getBalance?storeId=${this.ruleForm.storeId}`,
        method: "get",
        success: (res) => {
          if (res.state == 0) {
            this.balance = res.value || "0.00";
          }
        },
        fail: (err) => {},
      };
      this.$request(opt);
    },
    // 退款
    refund() {
      this.$refs.refund.open();
    },
    // 查看明细
    seeDetail() {
      this.$router.push({
        path: "/balanceDetails",
        query: {
          storeId: this.$route.query.storeId,
        },
      });
    },
  },
};
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb {
  height: 35px;
  text-align: right;
  margin: 20px 0;
}

.content {
  margin-bottom: 20px;
  padding: 20px;
  background: #fff;
}

.title {
  font-size: 14px;
  color: #1f2d3d;
  font-weight: 900;
  margin: 36px 18px;
}
</style>
